import { type MetaFunction, useLoaderData } from "@remix-run/react";
import Container from "~/components/layout/Container";
import { references } from "../_index";
import References from "../features/References";
import ServicesComponent from "../features/Services";
import Slogans from "../features/Slogans";
export const HizmetListesi = [
  {
    id: 1,
    title: "KOMBİ",
    description: `
          <div class="text__block mb-40">
                                    <p class="text__block-desc"></p><p><strong>Kombi Hizmetlerimiz: Konforlu ve Güvenli Isıtma Çözümleri</strong></p>
<p>Kombiler, evlerimizdeki ısınma ve sıcak su ihtiyacını karşılayan en önemli cihazlardır. Hem enerji verimliliği sağlamak hem de konforlu bir yaşam alanı sunmak amacıyla, profesyonel kombi hizmetlerimizle sizlere en iyi çözümleri sunuyoruz. Kombi bakım, onarım, kurulum ve arıza tespiti gibi alanlarda uzman ekibimizle hizmetinizdeyiz.</p>
<hr>
<h3><strong>Kombi Bakım Hizmeti</strong></h3>
<p>Kombiler, doğru bakım yapılmadığı takdirde verimlilik kaybına uğrar ve arızalar meydana gelebilir. Düzenli bakım, hem cihazın ömrünü uzatır hem de yüksek verimle çalışmasını sağlar. Kombi bakım hizmetimiz, aşağıdaki işlemleri kapsamaktadır:</p>
<ul>
<li><strong>Isıtma Sistemi Temizliği</strong>: Kombinin iç parçaları, borular ve filtreler temizlenir. Bu sayede, cihazın daha verimli çalışması sağlanır.</li>
<li><strong>Gaz Kaçağı Kontrolü</strong>: Gazlı kombilerde, olası gaz kaçakları tespit edilip gerekli güvenlik önlemleri alınır. Gaz sızıntısı, ciddi tehlikelere yol açabileceği için bu kontrol hayati önem taşır.</li>
<li><strong>Su Basıncı Kontrolü</strong>: Kombinin doğru bir şekilde çalışabilmesi için su basıncı kritik öneme sahiptir. Basınç seviyesi kontrol edilir ve gerekiyorsa ayarlanır.</li>
<li><strong>Sıcak Su ve Isıtma Verimliliği</strong>: Kombinin su ısıtma kapasitesi ve sıcaklık ayarları kontrol edilerek, verimlilik optimize edilir.</li>
</ul>
<hr>
<h3><strong>Kombi Onarım Hizmeti</strong></h3>
<p>Kombinizde bir arıza mı meydana geldi? Kombi onarım hizmetimiz ile hızlı ve etkili çözümler sunuyoruz. Deneyimli teknisyenlerimiz, arızanın kaynağını tespit eder ve gereken onarım işlemlerini titizlikle gerçekleştirir.</p>
<ul>
<li><strong>Elektronik Arızalar</strong>: Kombinizin elektronik aksamlarında meydana gelen arızalar, uzman ekibimiz tarafından tespit edilip, yedek parça değişimiyle onarılır.</li>
<li><strong>Yanma ve Ateşleme Sorunları</strong>: Kombinin ateşleme sisteminde meydana gelen sorunlar giderilir ve cihazın tekrar verimli çalışması sağlanır.</li>
<li><strong>Isı Dağıtımı Sorunları</strong>: Kombinin ısıtma sisteminde yaşanan verimsizlikler, tesisat kontrolü ile düzeltilir.</li>
<li><strong>Arızalı Termostat ve Sensörler</strong>: Kombinin termostatları ve sensörleri, doğru çalışmadığı takdirde değiştirilir veya onarılır.</li>
</ul>
<hr>
<h3><strong>Kombi Kurulum Hizmeti</strong></h3>
<p>Yeni bir kombi mi almayı düşünüyorsunuz? Kombi kurulum hizmetimiz, yeni kombi alımından sonra güvenli, hızlı ve doğru kurulumunuzu sağlar. Kurulum sırasında dikkat edilen bazı unsurlar:</p>
<ul>
<li><strong>Yer Seçimi ve Montaj</strong>: Kombi, verimli çalışabilmesi için doğru yere yerleştirilir. Yer seçimi, enerji verimliliği açısından çok önemlidir.</li>
<li><strong>Gaz ve Elektrik Bağlantıları</strong>: Kombinin gaz ve elektrik bağlantıları uzman teknisyenlerimiz tarafından yapılır. Herhangi bir güvenlik riski oluşmaması için gerekli tüm önlemler alınır.</li>
<li><strong>Sistemin Test Edilmesi</strong>: Kurulum sonrası kombinin tüm fonksiyonları test edilir. Su ısıtma ve ısınma sistemleri çalıştırılır ve doğru sıcaklık seviyelerine ulaşması sağlanır.</li>
</ul>
<hr>
<h3><strong>Kombi Arıza Tespiti ve Diagnostik Hizmeti</strong></h3>
<p>Kombinizde bir arıza olduğunu ancak ne olduğunu anlamadığınız durumlar olabilir. Kombi arıza tespiti hizmetimiz ile cihazınızdaki problemi hızlı bir şekilde belirleyip, çözüm önerileri sunuyoruz.</p>
<ul>
<li><strong>Kombinin Genel Durumunun İncelenmesi</strong>: Kombinizin tüm bileşenleri detaylı olarak incelenir. Elektronik kart, termostat, yanma sistemi gibi unsurlar gözden geçirilir.</li>
<li><strong>Sıcak Su ve Isıtma Kontrolü</strong>: Sıcak su verimliliği ve ısınma sisteminin düzgün çalışıp çalışmadığı kontrol edilir. Isıtma verimsizliği tespit edilirse, bu duruma neden olan arızalar belirlenir.</li>
<li><strong>Enerji Verimliliği Testi</strong>: Kombinizin enerji verimliliği kontrol edilerek, sistemin daha ekonomik çalışabilmesi için gerekli ayarlamalar yapılır.</li>
</ul>
<hr>
<h3><strong>Kombi Performans Artırma Hizmeti</strong></h3>
<p>Kombiniz eski olabilir veya yeterince verimli çalışmayabilir. Performans artırma hizmetimiz ile kombinizin verimliliğini artırarak, daha düşük enerji tüketimi sağlamanızı hedefliyoruz.</p>
<ul>
<li><strong>Enerji Verimliliği Optimizasyonu</strong>: Kombinizin daha verimli çalışması için gerekli tüm ayarlamalar yapılır. Bu sayede, enerji faturalarınızda ciddi tasarruflar sağlanır.</li>
<li><strong>Sıcak Su Kapasitesi Arttırma</strong>: Kombinizin sıcak su kapasitesi artırılır, böylece ihtiyacınız olan sıcak su miktarını rahatlıkla elde edebilirsiniz.</li>
<li><strong>Isıtma Alanının Genişletilmesi</strong>: Kombinin ısıtma gücü artırılarak, daha geniş alanlarda etkili ısınma sağlanır.</li>
</ul>
<hr>
<h3><strong>Kombi Yedek Parça Değişimi</strong></h3>
<p>Kombinizin arızalanan parçaları, orijinal yedek parçalarla değiştirilir. Yedek parça değişimi, kombinizin ömrünü uzatır ve verimliliğini artırır.</p>
<ul>
<li><strong>Orijinal Yedek Parça Kullanımı</strong>: Kombi arızasında kullanılan yedek parçalar, markanın önerdiği orijinal parçalarla değiştirilir. Bu, cihazın performansını ve güvenliğini artırır.</li>
<li><strong>Termostat, Pompa ve Fan Değişimi</strong>: Kombinin içindeki temel bileşenler arızalandığında, değişim yapılır. Fanlar, pompalar ve termostatlar değiştirilerek, cihazın verimli çalışması sağlanır.</li>
<li><strong>Gaz Valf ve Ateşleme Sistemi</strong>: Gaz valf ve ateşleme sistemi arızalarına karşı da hızlı çözümler sunulmaktadır.</li>
</ul>
<hr>
<h3><strong>Kombi Bakım ve Onarımda Uzman Hizmet</strong></h3>
<p><strong>Kombi hizmetlerimiz</strong>, uzman teknisyenler tarafından verilmektedir. Kombi bakımı, onarımı ve kurulumu konularında yıllardır süregelen tecrübemiz ile en kaliteli hizmeti sunuyoruz. Enerji tasarrufu sağlamak, yüksek verimlilik elde etmek ve cihazınızın ömrünü uzatmak için bizlere güvenebilirsiniz.</p><p></p>
                                </div>
        `,
    image: "/assets/kombi.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 3,
    title: "KAZAN",
    description: `<div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="text__block mb-40">
                                    <p class="text__block-desc"></p><p>Kazanlar, endüstriyel ve ticari işletmelerin ısıtma ve enerji ihtiyaçlarını karşılayan önemli ekipmanlardır. Uzun ömürlü ve verimli bir kazan kullanımı, işletmenizin enerji maliyetlerini önemli ölçüde düşürebilir. Bu nedenle, kazan sistemlerinin düzenli bakımı, kurulumu ve arızalarının doğru şekilde giderilmesi son derece önemlidir.</p>
<h3><strong>Kazan Kurulumu</strong></h3>
<p>Kazan kurulumu, uzmanlık ve deneyim gerektiren bir işlemdir. Doğru kurulum, kazanınızın verimli ve güvenli çalışmasını sağlar. Kazan montajında, tesisatın doğru yerleştirilmesi, gerekli güvenlik önlemlerinin alınması ve sistemin enerji verimliliğini optimize etmek için doğru ekipman kullanımı son derece önemlidir.</p>
<h4><strong>Kurulum Süreci:</strong></h4>
<ul>
<li><strong>Proje Tasarımı:</strong> Kurulum öncesinde, kazan ihtiyacınız belirlenir ve tesisatın yerleşim planı yapılır.</li>
<li><strong>Ekipman Seçimi:</strong> İhtiyacınıza uygun kazan seçimi yapılır.</li>
<li><strong>Montaj:</strong> Kazan ve bağlantılı sistemlerin montajı uzman ekiplerimiz tarafından titizlikle yapılır.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Kazan Bakımı</strong></h3>
<p>Kazan bakımı, cihazın uzun ömürlü ve verimli çalışmasını sağlamak için düzenli aralıklarla yapılmalıdır. Bakım işlemi, kazanınızın performansını artırır, arıza risklerini azaltır ve enerji tüketimini optimize eder.</p>
<h4><strong>Bakım Hizmetlerimiz:</strong></h4>
<ul>
<li><strong>Temizlik:</strong> Kazanın iç ve dış temizlik işlemleri yapılır.</li>
<li><strong>Filtre Kontrolü:</strong> Kazan sistemindeki filtrelerin düzenli olarak temizlenmesi sağlanır.</li>
<li><strong>Alevleme Kontrolü:</strong> Yanma sisteminin verimli çalışması için alevleme ayarları yapılır.</li>
<li><strong>Gaz Kaçağı Kontrolü:</strong> Kazanınızda olabilecek gaz kaçakları tespit edilir ve giderilir.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Kazan Arıza Tespiti ve Onarımı</strong></h3>
<p>Kazanlar zaman zaman arızalar verebilir. Bu tür arızaların hızlı ve doğru şekilde tespit edilmesi, işletmenizin kesintiye uğramaması açısından büyük önem taşır. Kazan arızalarını çözmek için uzman kadromuz, gelişmiş cihazlarla sisteminizi kontrol eder ve gerekli onarımları gerçekleştirir.</p>
<h4><strong>Arıza Tespiti:</strong></h4>
<ul>
<li><strong>Elektrik Arızaları:</strong> Elektriksel sorunlar, kazan sistemlerinin en yaygın arıza sebeplerindendir. Uzmanlarımız, elektriksel bağlantıları kontrol eder ve gerekli müdahaleyi yapar.</li>
<li><strong>Isı Sistemindeki Sorunlar:</strong> Isı kayıplarını engellemek için sıcaklık seviyelerinin düzgün çalışıp çalışmadığına bakılır.</li>
<li><strong>Su Basıncı Sorunları:</strong> Kazanların çalışabilmesi için doğru su basıncı çok önemlidir. Su basıncının düşük olması durumunda tespit yapılır.</li>
</ul>
<h4><strong>Onarım Süreci:</strong></h4>
<ul>
<li>Arıza tespiti yapılır.</li>
<li>Gerekli yedek parçalar temin edilir.</li>
<li>Arıza hızlı ve güvenli bir şekilde giderilir.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Kazan Yedek Parça Değişimi</strong></h3>
<p>Kazanların verimli çalışabilmesi için bazı parçaların düzenli olarak değiştirilmesi gerekebilir. Özellikle, zamanla aşınan ya da bozulabilen parçaların değiştirilmesi, cihazın uzun ömürlü olmasını sağlar. Yedek parça değişimlerinde yalnızca orijinal ve kaliteli parçalar kullanılmaktadır.</p>
<h4><strong>Yedek Parça Değişimi:</strong></h4>
<ul>
<li><strong>Alevleme Elektrodu:</strong> Alevleme elektrotlarının zamanla bozulması yaygın bir durumdur. Bu parçanın değiştirilmesi gerekmektedir.</li>
<li><strong>Termostat:</strong> Termostatın arızalanması, kazanın sıcaklık ayarını bozabilir. Bu parçanın değiştirilmesi gereklidir.</li>
<li><strong>Fırın Kafesi:</strong> Fırın kafesi hasar gördüğünde, yanma verimliliği düşebilir. Bu parça değiştirilir.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Enerji Verimliliği İyileştirme</strong></h3>
<p>Kazanlar enerji verimliliğini optimize edebilecek şekilde tasarlanmalıdır. Enerji verimli kazanlar, daha düşük yakıt tüketimi sağlar ve işletme maliyetlerini azaltır. Enerji verimliliği iyileştirme çalışmalarımızda, mevcut sistemin verimliliğini artırmak için çeşitli stratejiler uygulanır.</p>
<h4><strong>Verimlilik İyileştirme Çözümleri:</strong></h4>
<ul>
<li><strong>Yanma Sistemi Ayarları:</strong> Kazanın yanma verimliliği optimize edilir.</li>
<li><strong>Isı Geri Kazanımı:</strong> Kazandan elde edilen ısı, daha verimli kullanılmak üzere geri kazanılır.</li>
<li><strong>Düşük Emisyonlu Sistemler:</strong> Çevre dostu emisyon seviyelerine sahip kazan sistemleri tercih edilir.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Kazan Güvenliği</strong></h3>
<p>Kazanlar, özellikle yüksek sıcaklık ve basınç altında çalışan cihazlar olduğundan, güvenlik önlemleri son derece önemlidir. Kazan güvenliği, hem cihazın sağlığı hem de işletme güvenliği açısından kritik rol oynar.</p>
<h4><strong>Güvenlik Önlemleri:</strong></h4>
<ul>
<li><strong>Basınç Testleri:</strong> Kazanın basınç güvenliği sağlanır.</li>
<li><strong>Acil Durum Durdurma Sistemleri:</strong> Kazanın acil durumlarda hızlı bir şekilde durmasını sağlayan sistemler kontrol edilir.</li>
<li><strong>Yangın Güvenliği:</strong> Kazanın yangın güvenliği düzenlemelerine uygunluğu kontrol edilir.</li>
</ul><p></p>
                                </div>
                            </div>`,
    image: "/assets/kazan.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 5,
    title: "ŞOFBEN",
    description: `<div class="text__block mb-40">
                                    <p class="text__block-desc"></p><p>Şofben, günlük yaşamın en önemli ekipmanlarından biridir ve suyun hızlı bir şekilde ısınmasını sağlayarak konforlu bir yaşam sunar. Doğru şekilde seçilen ve bakım yapılan şofbenler, hem enerji tasarrufu sağlar hem de uzun ömürlü olmasına yardımcı olur. Bu nedenle şofbenin kurulumu, bakımı, onarımı ve yedek parça değişimi gibi hizmetler büyük önem taşır.</p>
<h3>&nbsp;</h3>
<h3><strong>Şofben Kurulumu</strong></h3>
<p>Şofben kurulumu, uzmanlık gerektiren bir hizmettir. Her evin ve her kullanım alanının ihtiyaçları farklı olabilir. Şofbenin doğru montajı, hem verimli kullanım hem de güvenlik açısından önemlidir. Bizimle çalışarak, enerji verimliliği yüksek ve güvenli bir şofben sistemine sahip olabilirsiniz.</p>
<h4><strong>Kurulum Süreci:</strong></h4>
<ul>
<li><strong>İhtiyaç Analizi:</strong> İhtiyacınıza uygun kapasitedeki şofben seçimi yapılır.</li>
<li><strong>Ekipman Seçimi:</strong> Elektrikli ya da gazlı şofben gibi ihtiyaçlarınıza uygun cihazlar tercih edilir.</li>
<li><strong>Montaj ve Bağlantılar:</strong> Şofbenin doğru bir şekilde yerleştirilmesi, su ve elektrik bağlantılarının yapılması sağlanır.</li>
<li><strong>Test ve Onay:</strong> Şofbenin doğru çalışıp çalışmadığı test edilir ve onaylanır.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Şofben Bakımı</strong></h3>
<p>Şofbenlerin uzun ömürlü ve verimli çalışabilmesi için düzenli bakım yapılması gerekir. Düzenli bakım sayesinde, şofbenin arızalanma riski azalır, su ısıtma verimliliği artar ve enerji tüketimi optimize edilir.</p>
<h4><strong>Bakım Hizmetlerimiz:</strong></h4>
<ul>
<li><strong>Temizlik:</strong> Şofbenin iç aksamlarının temizliği yapılır. Özellikle suyun kireçli olduğu bölgelerde, kireç tortuları temizlenir.</li>
<li><strong>Termostat Kontrolü:</strong> Şofbenin sıcaklık ayarlarının doğru yapıldığından emin olunur.</li>
<li><strong>Gaz ve Elektrik Bağlantıları:</strong> Bağlantılar kontrol edilerek, güvenli çalışması sağlanır.</li>
<li><strong>Su Basıncı Testi:</strong> Şofbenin düzgün çalışabilmesi için su basıncı kontrol edilir.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Şofben Arıza Tespiti ve Onarımı</strong></h3>
<p>Zaman zaman şofbenler arızalanabilir. Arızaların erken tespiti, büyük maliyetli hasarların önüne geçilmesine yardımcı olur. Şofbeninizde meydana gelen herhangi bir arızayı tespit etmek ve onarmak için uzman ekibimizden destek alabilirsiniz.</p>
<h4><strong>Arıza Tespiti:</strong></h4>
<ul>
<li><strong>Elektriksel Arızalar:</strong> Elektriksel bağlantılarda meydana gelen sorunlar tespit edilir ve giderilir.</li>
<li><strong>Su Isıtma Problemleri:</strong> Su sıcaklığı ile ilgili sorunlar kontrol edilerek çözülür.</li>
<li><strong>Gaz Kaçağı:</strong> Gazlı şofbenlerde oluşabilecek gaz kaçakları tespit edilip güvenli bir şekilde onarılır.</li>
</ul>
<h4><strong>Onarım Süreci:</strong></h4>
<ul>
<li>Arıza tespit edilir.</li>
<li>Gerekli yedek parçalar temin edilir ve değiştirilir.</li>
<li>Şofben yeniden test edilerek güvenli çalışması sağlanır.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Şofben Yedek Parça Değişimi</strong></h3>
<p>Şofbenin verimli çalışabilmesi için bazı parçaların zamanla değiştirilmesi gerekebilir. Yedek parça değişimi, şofbenin ömrünü uzatır ve performansını artırır. Yalnızca kaliteli ve orijinal yedek parçalar kullanılmaktadır.</p>
<h4><strong>Yedek Parça Değişimi:</strong></h4>
<ul>
<li><strong>Termostat:</strong> Sıcaklık kontrolünü sağlayan termostatın değiştirilmesi gerekebilir.</li>
<li><strong>Isıtma Elemanı:</strong> Isıtma elemanları zamanla aşınabilir, bu yüzden değişimi önemlidir.</li>
<li><strong>Gaz Valfleri:</strong> Gazlı şofbenlerde, gaz valfleri değiştirilebilir.</li>
<li><strong>Filtreler:</strong> Su filtresi ve hava filtrelerinin değiştirilmesi gerekebilir.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Enerji Verimliliği İyileştirme</strong></h3>
<p>Şofbenlerin enerji verimliliği, doğru kullanım ve bakım ile önemli ölçüde artırılabilir. Enerji verimli şofbenler, suyu daha az enerjiyle ısıtarak işletme maliyetlerini azaltır ve çevreye duyarlı bir kullanım sunar.</p>
<h4><strong>Verimlilik İyileştirme Çözümleri:</strong></h4>
<ul>
<li><strong>Sıcaklık Ayarları:</strong> Şofbenin sıcaklık ayarları, maksimum verimlilik için optimize edilir.</li>
<li><strong>Yüksek Verimli Modeller:</strong> Enerji verimliliği yüksek şofben modelleri tercih edilir.</li>
<li><strong>Su Kullanımı Düzenlemeleri:</strong> Su tüketimini azaltan çözümlerle verimlilik artırılır.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Şofben Güvenliği</strong></h3>
<p>Şofbenler, özellikle gazlı modellerde yüksek riskler taşıyabilir. Bu nedenle, şofben güvenliği, cihazın düzgün çalışması kadar önemlidir. Şofbenin güvenli çalışması için çeşitli önlemler alınır.</p>
<h4><strong>Güvenlik Önlemleri:</strong></h4>
<ul>
<li><strong>Gaz Kaçağı Tespiti:</strong> Gazlı şofbenlerde gaz kaçakları düzenli olarak kontrol edilir.</li>
<li><strong>Elektriksel Güvenlik:</strong> Elektrik bağlantılarının doğru yapıldığından ve topraklamanın düzgün olduğundan emin olunur.</li>
<li><strong>Acil Durdurma Sistemleri:</strong> Herhangi bir acil durumda, şofbenin hızlı bir şekilde kapanmasını sağlayan sistemler kontrol edilir.</li>
</ul><p></p>
                                </div>`,
    image: "/assets/sofben.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 6,
    title: "RADYATÖR",
    description: `<div class="text__block mb-40">
                                    <p class="text__block-desc"></p><p>Radyatörler, ısınma sistemlerinin en önemli bileşenlerinden biridir. Hem elektrikli hem de merkezi ısınma sistemlerinde kullanılan radyatörler, yaşam alanlarındaki sıcaklık dengesini sağlamak için büyük rol oynar. Radyatörlerin doğru kurulumu, bakımı, onarımı ve verimlilik iyileştirme işlemleri, hem enerji tasarrufu sağlar hem de uzun süre sorunsuz bir kullanım sunar.</p>
<h3>&nbsp;</h3>
<h3><strong>Radyatör Kurulumu</strong></h3>
<p>Radyatörlerin doğru kurulumu, ısınma sistemlerinin verimli çalışabilmesi için temel bir adımdır. Yeni bir sistem kurarken, uygun kapasitedeki radyatörlerin seçilmesi ve doğru yerleşimi sağlanmalıdır.</p>
<h4><strong>Kurulum Süreci:</strong></h4>
<ul>
<li><strong>İhtiyaç Analizi:</strong> Ev ya da işyerinizdeki alanın büyüklüğüne ve ısınma ihtiyacınıza uygun radyatör seçimi yapılır.</li>
<li><strong>Yer Seçimi:</strong> Radyatörün yerleştirileceği duvarın konumu, ısınma verimliliğini etkileyebilir. En uygun yer tespiti yapılır.</li>
<li><strong>Montaj:</strong> Seçilen radyatör, uygun bağlantılarla duvara montelenir ve tesisata bağlanır.</li>
<li><strong>Test ve Ayar:</strong> Radyatörlerin düzgün çalışıp çalışmadığı test edilir ve sıcaklık ayarları yapılır.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Radyatör Bakımı</strong></h3>
<p>Radyatörlerin düzenli bakımı, verimli çalışmasını ve uzun ömürlü olmasını sağlar. Bakım sırasında, radyatörlerin içi temizlenir, hava ve su sızıntıları kontrol edilir, su seviyesi düzenlenir ve sıcaklık ayarları yapılır.</p>
<h4><strong>Bakım Hizmetlerimiz:</strong></h4>
<ul>
<li><strong>Radyatör Temizliği:</strong> Radyatörlerin iç kısmındaki tortu ve kirler temizlenir, suyun dolaşımını engelleyen tıkanıklıklar giderilir.</li>
<li><strong>Hava Alma:</strong> Radyatörlerde hava birikmesi, ısınma performansını düşürebilir. Hava alma işlemi yapılır.</li>
<li><strong>Su Seviyesi Kontrolü:</strong> Merkezi ısınma sistemlerinde, radyatörlerdeki su seviyesi kontrol edilir ve gerektiğinde su eklenir.</li>
<li><strong>Termostat Kontrolü:</strong> Radyatörün sıcaklık ayarlarının doğru yapıldığından emin olunur.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Radyatör Arıza Tespiti ve Onarımı</strong></h3>
<p>Radyatörlerde zaman zaman çeşitli arızalar meydana gelebilir. Bu arızalar, ısınma sisteminin verimliliğini düşürebilir ve enerji kaybına yol açabilir. Radyatör arızalarını tespit edip, kısa sürede onarımını yaparak tekrar verimli bir kullanım sağlanır.</p>
<h4><strong>Arıza Tespiti:</strong></h4>
<ul>
<li><strong>Su Sızıntıları:</strong> Radyatörlerdeki su sızıntıları, hem verimliliği düşürür hem de su kaybına neden olabilir. Sızıntıların tespiti yapılır.</li>
<li><strong>Isınmama Problemi:</strong> Radyatörlerin düzgün ısınmaması, hava birikmesi ya da su dolaşımındaki aksaklıklar nedeniyle olabilir. Bu sorunlar tespit edilir ve giderilir.</li>
<li><strong>Termostat Arızaları:</strong> Sıcaklık kontrolü sağlanamıyorsa, termostatlar kontrol edilerek onarılır.</li>
</ul>
<h4><strong>Onarım Süreci:</strong></h4>
<ul>
<li>Arıza tespit edilir ve gerekli yedek parçalar temin edilir.</li>
<li>Arızalı parçalar değiştirilir ve testler yapılır.</li>
<li>Radyatörün tekrar güvenli bir şekilde çalışması sağlanır.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Radyatör Yedek Parça Değişimi</strong></h3>
<p>Radyatörler, çeşitli parçaların birleşiminden oluşur. Zaman içinde bu parçalar aşınabilir veya arızalanabilir. Yedek parça değişimi, radyatörün uzun ömürlü olmasını ve yüksek verimle çalışmasını sağlar.</p>
<h4><strong>Yedek Parça Değişimi:</strong></h4>
<ul>
<li><strong>Termostat:</strong> Radyatörün sıcaklık ayarlarını sağlayan termostatın değiştirilmesi gerekebilir.</li>
<li><strong>Vanalar:</strong> Su akışını kontrol eden vanalar zamanla aşınabilir, bu durumda vanaların değiştirilmesi gerekebilir.</li>
<li><strong>Filtreler:</strong> Su ve hava filtrelerinin temizliği ve değişimi, verimliliği artırabilir.</li>
<li><strong>Bağlantı Elemanları:</strong> Radyatörlerin su borularına bağlantı yapan elemanlar, zamanla sızıntı yapabilir ve değişmesi gerekebilir.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Radyatör Verimlilik İyileştirme</strong></h3>
<p>Radyatörlerin verimliliğini artırmak, hem enerji tasarrufu sağlar hem de ısınma maliyetlerini düşürür. Verimlilik iyileştirmeleri, daha etkili ısınma ve düşük enerji tüketimi sağlar.</p>
<h4><strong>Verimlilik İyileştirme Çözümleri:</strong></h4>
<ul>
<li><strong>Yüksek Verimli Radyatör Modelleri:</strong> Eski radyatörler, yeni ve verimli modellerle değiştirilebilir.</li>
<li><strong>Yalıtım Çözümleri:</strong> Radyatörlerin etrafındaki duvarlara izolasyon eklenerek, ısı kaybı önlenebilir.</li>
<li><strong>Radyatör Yansıtıcıları:</strong> Radyatörlerin arkasına ısıyı yansıtan malzemeler ekleyerek, odanın daha hızlı ısınması sağlanabilir.</li>
<li><strong>Sıcaklık Ayarları:</strong> Sıcaklık ayarları, en verimli şekilde yapılır.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Radyatör Güvenliği</strong></h3>
<p>Radyatörlerin güvenliği, hem kullanıcı sağlığı hem de enerji verimliliği açısından büyük önem taşır. Şüpheli durumlar hemen tespit edilip, gerekli güvenlik önlemleri alınır.</p>
<h4><strong>Güvenlik Önlemleri:</strong></h4>
<ul>
<li><strong>Su Sızıntısı Tespiti:</strong> Herhangi bir su sızıntısı tespit edilirse, derhal müdahale edilir.</li>
<li><strong>Termostat Güvenliği:</strong> Aşırı ısınma durumlarına karşı termostatlar kontrol edilerek güvenli sıcaklık sınırları sağlanır.</li>
<li><strong>Elektriksel Güvenlik:</strong> Elektrikli radyatörler için elektriksel güvenlik önlemleri alınır ve bağlantılar kontrol edilir.</li>
</ul><p></p>
                                </div>`,
    image: "/assets/radyator.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 2,
    title: "ISI POMPASI",
    description: `<div class="text__block mb-40">
                                    <p class="text__block-desc"></p><p>Isı pompası, enerji verimli ısıtma ve soğutma sistemleri arasında önemli bir yer tutar. Elektrik enerjisini verimli bir şekilde kullanarak, çevresindeki havadan, suyun sıcaklığından ya da yer altı ısısından ısı transferi yapar. Hem ısıtma hem de soğutma işlevi görmesi sayesinde, yıl boyunca konforlu bir iç ortam yaratır. Isı pompası hizmetimiz, enerji tasarrufu sağlamak, çevre dostu çözümler sunmak ve sistemlerin verimliliğini artırmak amacıyla kapsamlı bir şekilde sunulmaktadır.</p>
<h3>&nbsp;</h3>
<h3><strong>Isı Pompası Sistemi Kurulumu</strong></h3>
<p>Isı pompası kurulumu, uzmanlık gerektiren bir süreçtir. Enerji verimliliğini en üst düzeye çıkarmak ve uzun ömürlü sistemler oluşturmak için doğru planlama ve montaj gereklidir. Isı pompası, doğru yerleşim ve yüksek kaliteli malzemelerle kurulduğunda maksimum verimlilik sağlar.</p>
<h4><strong>Kurulum Süreci:</strong></h4>
<ul>
<li><strong>İhtiyaç Analizi ve Tasarım:</strong> İlk adım, müşterinin ihtiyacını anlamak ve buna uygun bir ısı pompası sistemi tasarlamaktır. İhtiyaçlar doğrultusunda sistem kapasitesi belirlenir.</li>
<li><strong>Donanım Seçimi:</strong> Farklı ısı pompası tipleri (hava kaynaklı, su kaynaklı, yer kaynaklı) arasından en uygun olanı seçilir.</li>
<li><strong>Sistem Yerleşimi:</strong> Isı pompasının yerleştirileceği alanın analizi yapılır. Sistemin verimli çalışabilmesi için doğru yerleşim çok önemlidir.</li>
<li><strong>Montaj ve Bağlantılar:</strong> Isı pompası ünitesi, borulama ve elektrik bağlantıları yapılır. İlgili ekipmanlar monte edilir ve sistem devreye alınır.</li>
<li><strong>Test ve Onay:</strong> Kurulum tamamlandıktan sonra, sistemin düzgün çalışıp çalışmadığı kontrol edilir. İlk testler yapılır ve gerekli ayarlamalar yapılır.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Isı Pompası Bakımı ve Yedek Parça Hizmetleri</strong></h3>
<p>Isı pompasının uzun süre verimli çalışması için düzenli bakım gereklidir. Düzenli bakım, sistemin verimliliğini artırırken, olası arızaların önüne geçer. Ayrıca, istenilen performansı sürdürebilmek için yedek parça değişimi de zaman zaman gerekebilir.</p>
<h4><strong>Bakım Hizmetlerimiz:</strong></h4>
<ul>
<li><strong>Filtre Temizliği ve Değişimi:</strong> Isı pompasındaki hava filtrelerinin düzenli olarak temizlenmesi, havanın verimli bir şekilde ısıtılmasını ve soğutulmasını sağlar.</li>
<li><strong>Kompressor Kontrolü:</strong> Kompresörlerin düzgün çalışıp çalışmadığı düzenli olarak kontrol edilir. Arıza durumunda onarım veya parça değişimi yapılır.</li>
<li><strong>Boru ve Kollektör Temizliği:</strong> Isı pompası sistemi içindeki boru hatları ve kolektörler temizlenir, verimli ısı transferi sağlanır.</li>
<li><strong>Sistemin Genel Kontrolü:</strong> Sistemin tüm bileşenleri, verimli bir şekilde çalışıp çalışmadığı açısından gözden geçirilir. Elektrik bağlantıları, basınç ayarları ve diğer bileşenler kontrol edilir.</li>
</ul>
<h4><strong>Yedek Parça Değişimi:</strong></h4>
<ul>
<li><strong>Kompressör Değişimi:</strong> Kompresör, ısı pompasının kalbidir. Arıza durumunda, yüksek kaliteli yedek kompresörle değiştirilir.</li>
<li><strong>Termostat ve Elektronik Parçalar:</strong> Termostat arızası durumunda, yedek parça değişimi yapılır.</li>
<li><strong>Borulama Sistemleri:</strong> Borularda oluşan sızıntılar onarılır veya boru hatları değiştirilir.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Isı Pompası Arıza Tespiti ve Onarım</strong></h3>
<p>Isı pompası sistemlerinde meydana gelen arızalar, ısıtma ve soğutma performansını doğrudan etkiler. Erken tespit ve doğru onarım, sistemin daha uzun süre verimli çalışmasını sağlar.</p>
<h4><strong>Arıza Tespiti:</strong></h4>
<ul>
<li><strong>Isı Dağılımında Sorunlar:</strong> Sistemde ısıtma ya da soğutma verimliliği düşerse, olası arızalar tespit edilir. Kompresör, fan, termostat ve diğer bileşenler gözden geçirilir.</li>
<li><strong>Sistem Basınç Kontrolü:</strong> Düşük basınç veya yüksek basınç sorunları, sistemdeki arızaların belirtisi olabilir.</li>
<li><strong>Elektriksel Arızalar:</strong> Elektrik bağlantılarındaki aksaklıklar, sigorta sorunları ya da kompresör arızaları tespit edilerek onarılır.</li>
</ul>
<h4><strong>Onarım Süreci:</strong></h4>
<ul>
<li>Arızalı bileşenler tespit edilir ve yedek parçalarla değiştirilir.</li>
<li>Sistem, doğru şekilde çalışacak şekilde test edilir ve verimlilik kontrolü yapılır.</li>
<li>Müşteriye, sistemin gelecekteki bakımı ve verimliliği hakkında önerilerde bulunulur.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Isı Pompası Verimliliği ve Enerji Tasarrufu Çözümleri</strong></h3>
<p>Isı pompaları, doğru şekilde kullanıldığında büyük enerji tasarrufu sağlar. Sistem verimliliğini artırmak için uygulayabileceğiniz bazı iyileştirmeler vardır.</p>
<h4><strong>Verimlilik İyileştirme Çözümleri:</strong></h4>
<ul>
<li><strong>Isı Pompası Yeri Seçimi:</strong> Isı pompasının verimli çalışabilmesi için doğru konumlandırma çok önemlidir. Soğuk havaların olduğu alanlarda yer kaynaklı ısı pompaları daha verimli çalışabilir.</li>
<li><strong>İzleme Sistemleri Kurulumu:</strong> Sistem performansı, uzaktan izleme ve yönetim sistemleri ile takip edilebilir. Anlık izleme, verimliliği artırabilir.</li>
<li><strong>İzolasyon Uygulamaları:</strong> Binada yapılan izolasyon çalışmaları, ısı kaybını önler ve ısı pompasının daha az enerji harcamasını sağlar.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Isı Pompası Güvenliği ve Uzun Ömürlü Kullanım</strong></h3>
<p>Isı pompası sisteminin güvenliği, hem kullanıcı sağlığını hem de sistemin uzun ömürlü olmasını sağlar. Periyodik bakım ve güvenlik önlemleri, sistemin verimli bir şekilde çalışmasını sağlar.</p>
<h4><strong>Güvenlik Önlemleri:</strong></h4>
<ul>
<li><strong>Elektriksel Güvenlik:</strong> Elektrik bağlantıları ve sistem bileşenleri, güvenli kullanım için düzenli olarak kontrol edilir.</li>
<li><strong>Yangın Güvenliği:</strong> Isı pompaları, yangın riski oluşturmayacak şekilde tasarlanır ve düzenli kontrol edilir.</li>
<li><strong>Baskı ve Termal Güvenlik:</strong> Isı pompası sisteminde termal aşırı ısınma veya basınç artışı gibi durumların önlenmesi için gerekli güvenlik sistemleri entegre edilir.</li>
</ul><p></p>
                                </div>`,
    image: "/assets/isi-pompasi.webp", // Görsel dosya yolunu güncelle
  },

  {
    id: 7,
    title: "DOĞAL GAZ",
    description: `
          <div class="text__block mb-40">
                                    <p class="text__block-desc"></p><p>Doğalgaz, konforlu bir yaşamın en önemli unsurlarından biri olarak, modern yaşamın vazgeçilmez enerji kaynaklarından biridir. Isıtma, sıcak su temini ve mutfaklarda yemek pişirme gibi birçok farklı kullanım alanına sahip olan doğalgaz, hem çevre dostu hem de ekonomik bir enerji kaynağıdır. Doğalgaz hizmetimiz, her türlü kurulum, bakım, onarım ve güvenlik önlemleriyle müşterilerimize yüksek verimli ve güvenli çözümler sunmayı hedeflemektedir.</p>
<h3>&nbsp;</h3>
<h3><strong>Doğalgaz Kurulumu ve Montaj Hizmeti</strong></h3>
<p>Doğalgaz tesisatının kurulum süreci, uzmanlık gerektiren önemli bir adımdır. Bu süreç, güvenli ve verimli bir sistemin inşa edilmesi adına dikkatle planlanmalıdır. Müşterilerimize en iyi hizmeti sunabilmek için, doğru malzeme seçimi ve uygulama çok önemlidir.</p>
<h4><strong>Kurulum Süreci:</strong></h4>
<ul>
<li><strong>Proje Planlama ve İhtiyaç Analizi:</strong> İlk adım olarak, binanın doğalgaz kullanımı için gerekli olan kapasite ve sistem tasarımı yapılır. Bu aşamada, ev veya iş yerinin ihtiyaçları göz önünde bulundurularak en uygun sistem belirlenir.</li>
<li><strong>Malzeme Seçimi ve Temini:</strong> Doğalgaz tesisatında kullanılan malzemelerin kaliteli ve güvenli olması çok önemlidir. Tesisat boruları, vanalar, sayaçlar ve diğer bileşenler titizlikle seçilir.</li>
<li><strong>Tesisat Montajı:</strong> Doğalgaz tesisat boruları yerleştirilir, ana hatlar, bağlantı noktaları ve sayaçlar kurulur. Bu aşama, tesisatın düzgün çalışabilmesi için doğru bir şekilde yapılmalıdır.</li>
<li><strong>Kontroller ve Testler:</strong> Kurulum tamamlandıktan sonra, sistemin sızdırmazlık testleri yapılır. Gaz kaçakları veya herhangi bir arıza olup olmadığı kontrol edilir.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Doğalgaz Bakım ve Periyodik Kontrol Hizmetleri</strong></h3>
<p>Doğalgaz sistemi düzenli bakım ve kontrol gerektiren bir altyapıdır. Periyodik bakım, sistemin verimli çalışmasını sağlarken, olası arızaların önlenmesini de sağlar. Ayrıca, doğalgaz sistemlerinde güvenlik kritik bir öneme sahiptir.</p>
<h4><strong>Bakım Hizmetlerimiz:</strong></h4>
<ul>
<li><strong>Tesisat Kontrolü:</strong> Boru hatları, vanalar ve bağlantı noktalarının düzenli olarak kontrol edilmesi gerekir. Herhangi bir sızıntı, tıkanıklık veya deformasyon tespit edildiğinde, hemen onarım yapılır.</li>
<li><strong>Gaz Kaçağı Kontrolü:</strong> Doğalgaz sisteminin sızdırmazlık testleri yapılır. Gaz kaçağı riski taşıyan noktalar belirlenir ve gerekli onarımlar yapılır.</li>
<li><strong>Cihaz Bakımı:</strong> Doğalgazlı cihazlar (kombi, şofben, ocak vb.) düzenli olarak bakıma alınarak verimli çalışmaları sağlanır. Cihazların temizliği, parça değişimi ve ayarları yapılır.</li>
</ul>
<h4><strong>Periyodik Kontrol:</strong></h4>
<ul>
<li><strong>Gaz Sayacı Kontrolü:</strong> Sayacın doğru ölçüm yapıp yapmadığı periyodik olarak kontrol edilir. Yanlış ölçüm durumunda, sayaç değişimi gerçekleştirilir.</li>
<li><strong>Emniyet Sistemi Kontrolü:</strong> Doğalgaz sistemlerinde bulunan emniyet vanaları, termostatlar ve güvenlik sensörleri düzenli olarak test edilir.</li>
<li><strong>Baca ve Havalandırma Sistemleri:</strong> Baca temizliği ve havalandırma sistemleri de güvenlik açısından kritik öneme sahiptir. Periyodik olarak temizlenir ve kontrol edilir.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Doğalgaz Arıza Onarım Hizmetleri</strong></h3>
<p>Doğalgaz sistemlerinde meydana gelen arızalar, genellikle kullanıcı konforunu olumsuz etkiler ve güvenlik riski oluşturabilir. Erken tespit ve hızlı müdahale, arızaların büyümeden çözülmesini sağlar.</p>
<h4><strong>Arıza Tespiti:</strong></h4>
<ul>
<li><strong>Gaz Kaçağı Tespiti:</strong> Gaz kaçağı, doğalgaz sistemlerinde en önemli güvenlik sorunu olabilir. Gaz kaçakları tespit edilerek hızlı bir şekilde onarılır.</li>
<li><strong>Baca Tıkanıklığı:</strong> Baca tıkanıklıkları, doğalgazın düzgün bir şekilde tahliye edilmesini engelleyebilir. Baca tıkanıklıkları tespit edilerek temizlenir.</li>
<li><strong>Cihaz Arızaları:</strong> Kombi, şofben, doğalgazlı ocak gibi cihazlarda meydana gelen arızalar, uzman ekiplerimiz tarafından hızlı bir şekilde tespit edilir ve onarılır.</li>
</ul>
<h4><strong>Onarım Süreci:</strong></h4>
<ul>
<li>Arızalı bileşenler tespit edilir ve değiştirilir.</li>
<li>Gerekli tüm testler yapılır ve sistemin güvenliği sağlanır.</li>
<li>Müşteri, arıza ve onarım süreci hakkında bilgilendirilir.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Doğalgaz Güvenliği ve Kullanım İpuçları</strong></h3>
<p>Doğalgaz, doğru kullanıldığında güvenli bir enerji kaynağıdır. Ancak, güvenlik önlemleri alınmazsa tehlikeli olabilir. Doğalgaz sistemlerinin güvenli bir şekilde kullanılması ve bakımının yapılması önemlidir.</p>
<h4><strong>Güvenlik Önlemleri:</strong></h4>
<ul>
<li><strong>Gaz Kaçağı Algılama Cihazları:</strong> Gaz kaçağını tespit etmek için gaz algılama cihazlarının kullanımı önerilir.</li>
<li><strong>Düzenli Bakımlar:</strong> Doğalgaz tesisatının periyodik bakımını yaparak sistemin güvenliğini sağlamak önemlidir.</li>
<li><strong>Yedek Parça Kullanımı:</strong> Yedek parça değişimi gerektiğinde, yalnızca orijinal parçalar kullanılmalıdır.</li>
<li><strong>Havalandırma Sağlanması:</strong> Doğalgaz cihazlarının bulunduğu alanın yeterli havalandırmaya sahip olması gerekir. Aksi takdirde, karbon monoksit birikmesi gibi ciddi güvenlik sorunları yaşanabilir.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Doğalgaz Fiyatları ve Tasarruf Çözümleri</strong></h3>
<p>Doğalgaz kullanımı, yüksek enerji tüketimi nedeniyle maliyetli olabilir. Ancak doğru sistem seçimi, verimli cihaz kullanımı ve iyi bir izolasyon ile enerji tasarrufu sağlanabilir. Bizim sunduğumuz doğalgaz çözümleri, uzun vadede enerji maliyetlerinizi düşürmeye yardımcı olur.</p>
<h4><strong>Tasarruf Çözümleri:</strong></h4>
<ul>
<li><strong>Verimli Cihaz Kullanımı:</strong> Enerji verimli doğalgazlı cihazlar kullanarak, enerji tüketimi düşürülebilir.</li>
<li><strong>Isı Yalıtımı:</strong> Bina yalıtımının doğru yapılması, doğalgazın daha verimli kullanılmasını sağlar.</li>
<li><strong>Programlanabilir Termostatlar:</strong> Kombi ve şofbenlerde programlanabilir termostat kullanarak, gereksiz enerji harcamasının önüne geçilebilir.</li>
</ul><p></p>
                                </div>
        `,
    image: "/assets/dogalgaz.webp", // Görsel dosya yolunu güncelle
  },
  {
    id: 8,
    title: "PETEK TEMİZLİĞİ",
    description: `<div class="text__block mb-40">
                                    <p class="text__block-desc"></p><h3><strong>Petek Temizliği Hizmeti: Isınma Sisteminizin Verimliliğini Artırın ve Enerji Tasarrufu Sağlayın</strong></h3>
<p>Petekler, evlerdeki ısınma sistemlerinin bel kemiğini oluşturur, ancak zaman içinde kireç, kir, tortu ve pas birikintileri nedeniyle petekler verimsiz hale gelir. Bu, ısınma maliyetlerinizi artırabilir ve evinizin her köşesini yeterince ısınmamasına neden olabilir. Petek temizliği, peteklerin daha verimli çalışmasını sağlayarak enerji tasarrufu yapmanıza yardımcı olur ve ısınma sisteminizin ömrünü uzatır. Uzman ekibimiz tarafından yapılan profesyonel petek temizliği hizmetimiz, kombinizin ve peteklerinizin maksimum verimlilikle çalışmasını sağlar.</p>
<h3><strong>Petek Temizliği Neden Önemlidir?</strong></h3>
<p>Isınma sisteminizin etkinliği zamanla kir, kireç ve pas birikintileri nedeniyle düşer. Peteklerin içinde biriken bu maddeler, ısının homojen bir şekilde yayılmasını engeller, bu da enerji tüketiminin artmasına ve evinizin her alanının yeterince ısınmamasına yol açar. Düzenli petek temizliği ile bu sorunları ortadan kaldırabilir, hem enerji verimliliğinizi artırabilir hem de daha düşük enerji faturalarıyla ısınabilirsiniz.</p>
<h4><strong>Petek Temizliği Süreci:</strong></h4>
<ol>
<li>
<p><strong>Ön Hazırlık ve Kontrol:</strong> Temizliğe başlamadan önce, peteklerde herhangi bir arıza olup olmadığı kontrol edilir. Sistemdeki suyun sıcaklığı, basıncı ve genel durumu değerlendirilir. Sistem güvenliği sağlandıktan sonra temizlik işlemlerine başlanır.</p>
</li>
<li>
<p><strong>Peteklerin Derinlemesine Temizliği:</strong> Peteklerin iç kısmındaki kireç, tortu, pas ve kirler, özel cihazlar ve kimyasal temizleyicilerle temizlenir. Temizleme işlemi, peteklerin verimli bir şekilde ısınmasını ve enerji tüketiminin azaltılmasını sağlar. Bu işlem, peteklerinizi daha verimli hale getirir ve ısının her köşeye eşit şekilde yayılmasını sağlar.</p>
</li>
<li>
<p><strong>Sistem Temizliği ve Test Edilmesi:</strong> Petek temizliğinin ardından, ısınma sisteminin genel durumu gözden geçirilir. Kombi ve petekler arasındaki bağlantılar kontrol edilerek sistemin düzgün çalıştığından emin olunur. Tüm cihazlar test edilir ve olası arızalar erkenden tespit edilerek giderilir.</p>
</li>
<li>
<p><strong>Su Basıncı ve Ayarları:</strong> Peteklerin temizliği sırasında, sistemin su basıncı kontrol edilir. Gerekli ayarlamalar yapılarak sistemin optimum seviyede çalışması sağlanır.</p>
</li>
</ol>
<h4><strong>Petek Temizliğinin Faydaları:</strong></h4>
<ul>
<li><strong>Enerji Tasarrufu:</strong> Petekler temizlendikçe ısınma verimliliği artar, bu da enerji tüketiminin düşmesine ve düşük ısınma maliyetlerine yol açar.</li>
<li><strong>Evinizin Daha İyi Isınması:</strong> Temiz petekler, ısının her bölgeye eşit şekilde yayılmasını sağlar, böylece evinizin her köşesi yeterince ısınır.</li>
<li><strong>Uzun Ömürlü Isınma Sistemi:</strong> Düzenli temizlik, peteklerin ve kombinizin ömrünü uzatır, arızaların önüne geçer.</li>
<li><strong>Çevre Dostu:</strong> Daha verimli çalışan bir ısınma sistemi, çevreye daha az zarar verir ve karbon ayak izinizi küçültür.</li>
</ul>
<h3>&nbsp;</h3>
<h3><strong>Petek Temizliği İle Kombi ve Isınma Sistemi Bakımını Tamamlayın</strong></h3>
<p>Petek temizliği sadece peteklerinizi değil, aynı zamanda kombi ve ısınma sisteminizin tümünü etkiler. Kombi bakımı ile birlikte yapılacak petek temizliği, ısınma sisteminizin performansını maksimum seviyeye çıkarır. Kombinizin verimli çalışmasını sağlarken, peteklerinizin temizlenmesi, enerji verimliliğinizi artırarak tasarruf etmenizi sağlar. Bu yüzden petek temizliği, kombi bakımıyla birlikte düzenli aralıklarla yapılmalıdır.</p><p></p>
                                </div>`,
    image: "/assets/petek-temizligi.webp", // Görsel dosya yolunu güncelle
  }

];
export const loader = async () => {
  return {
    services: HizmetListesi,
    references: references
  }
}
export const meta: MetaFunction = () => {
  return [
    { title: "Al-De Mühendislik | Hizmetlerimiz" },
  ]
}
export default function Page() {
  const { services, references } = useLoaderData<typeof loader>();
  return (
    <div className="pt-[122px]">
      <div className="">
        <Container className="flex-wrap">
          <ServicesComponent services={services} />
        </Container>
      </div>
      <Slogans />
      <References references={references} />
    </div>
  )
}
